import React, { useState } from 'react'
import './Checkout.css';

function Checkout() {

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const proceed = () => {
    window.location.href = 'payment/';
  }

  return (
    <div className='main-bg'>
      <div className="user-info">
        <h1 style={{ fontWeight: 'bold' }}>User Details</h1>
        <form className='information'>
          <div className='info'>
            <h3>Name: </h3><input type="text" className="input-box" placeholder="Enter your name" name='name' />
          </div>
          <div className='info'>
            <h3>Flat/Building/Society: </h3><input type="text" className="input-box" placeholder="Flat/Building/Society" name='address' /></div>
          <div className='info'>
            <h3>City: </h3><input type="text" className="input-box" placeholder="City" name='city' /></div>
          <div className='info'>
            <h3>pincode: </h3><input type="text" className="input-box" placeholder="Pincode" name='pincode' /></div>
        </form>
        <button style={{ width: 'auto', height: '35px', fontSize: '18px', borderRadius: '15px', border: '2px solid black', background: 'white', paddingLeft: '10px', paddingRight: '10px' }} onClick={proceed}>Proceed to Pay</button>
      </div>
      <div className="product-details">
        <h1>Product details</h1>
      </div>
    </div>
  )
}

export default Checkout
